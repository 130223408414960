import React from "react"
import { withStyles, withWidth } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { GatsbyImage, useFluidUrl } from "../images"
import { useInView } from "react-intersection-observer"
import useScrollPosition from "../../hooks/useScrollPosition"
import useShouldAnimate from "../../hooks/useShouldAnimate"
import { ContentContainer } from "./content-container"

function SinglePageApplicationSupport(props) {
  const { classes, title, description, description2, width } = props
  const img = useFluidUrl(  'appSupportImg')

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    delay: 500,
  })

  const scrollPosition = useScrollPosition()
  const sectionRef = React.useRef()
  const sectionPosition = sectionRef?.current?.offsetTop
  const shouldAnimate = useShouldAnimate(sectionPosition, scrollPosition)

  function prepareAnimationClasses(className) {
    if (!shouldAnimate) return `${className} ${classes.visible}`
    if (inView) return `${className} ${classes.itemAnimation}`
    return className
  }

  function renderTitle() {
    const animationClasses = prepareAnimationClasses(classes.title)

    return (
      <Typography variant="h3" className={animationClasses}>
        Single page applications first class support
      </Typography>
    )
  }

  function renderInfoBlocks() {
    const animationClasses = prepareAnimationClasses(classes.infoWrapper)

    return (
      <div className={animationClasses}>
        <Typography variant="body1" className={`${classes.infoBlock}`}>
          It is especially beneficial for single page applications built with technologies like Meteor and GraphQL as you cannot predict UX only taking into consideration results of REST API load testing or initial page load metrics.
        </Typography>

        <Typography variant="body1" className={`${classes.infoBlock}`}>
          Astraload tracks users paths, websocket and GraphQL, resources origins to help you see where UX may have been degraded
        </Typography>
      </div>
    )
  }

  return (
    <ContentContainer ref={sectionRef} customClass={classes.containerBg}>
      <div className={classes.container}>
        <Grid
          container
          spacing={6}
          justifyContent={"space-between"}
        >
          <Grid
            item
            lg={6}
            md={12}
          >
            <div className={classes.textContainer}>
              {renderTitle()}
              {renderInfoBlocks()}
            </div>
          </Grid>
          <Grid
            item
            lg={6}
            md={12}
          >
            <div ref={ref} >
              <div className={prepareAnimationClasses(classes.dashboardImage)}>
                <img src={img.src} alt="" />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

    </ContentContainer>
  )
}

const styles = theme => {
  return {
    ...theme.animations.fadeInUp,
    containerBg: {
      background: "linear-gradient(180deg, #ECF2F6 0%, #FFFFFF 100%);"
    },
    container: {
      position: "relative"
    },
    infoWrapper: {
      opacity: 0
    },
    textContainer: {
      maxWidth: 500,

      [theme.breakpoints.down("md")]: {
        maxWidth: "auto",
      },
    },
    title: {
      color: theme.palette.text.secondary,
      marginBottom: 30,
      maxWidth: 520,
      opacity: 0,

      [theme.breakpoints.down("xs")]: {
        fontSize: 30,
        lineHeight: "40px",
      },
    },
    infoBlock: {
      color: theme.palette.text.primary,
      fontSize: 24,
      lineHeight: "38px",
      fontWeight: 300,

      [theme.breakpoints.down("xs")]: {
        fontSize: 20,
        lineHeight: "30px",
      },

      "&:not(:last-child)": {
        marginBottom: 40
      }
    },
    dashboardImage: {
      maxWidth: 570,
      width: "100%",
      animationDelay: "0.3s !important",
      opacity: 0,

      "& img": {
        width: "100%",
        display: "block"
      },
    },
    itemAnimation: {
      ...theme.animations.itemFadeInUp,
    },
    visible: {
      opacity: 1,
    },
  }
}

// eslint-disable-next-line
SinglePageApplicationSupport = withWidth()(
  withStyles(styles)(SinglePageApplicationSupport)
)

export { SinglePageApplicationSupport }
