import React from "react"
import Seo from "../components/seo"
import { Layout } from "../components/organism/layout"
import { DifferentFromCompetitorsSaas } from "../components/moleculs/different-from-competitors-saas"
import { SinglePageApplicationSupport } from "../components/moleculs/single-page-application-support"
import { IntelligentAssistantBlock } from "../components/moleculs/intelligent-assistant-block"
import { Constants } from "../constants"
import { TextBlock } from "../components/moleculs/text-block"
import { ContactsBlock } from "../components/organism/contacts-block"

const IndexPage = ({ location }) => {
  const {
    saasHeader,
    singlePageSupport,
    saasIntelligantAssiatant,
    saasCallToActionBlock,
  } = Constants

  return (
    <Layout background="newSaasBG" renderButton={false} {...saasHeader} saas>
      <Seo
        title="SaaS"
        description={saasHeader.description}
        location={location}
      />
      <main>
        <DifferentFromCompetitorsSaas />

        <SinglePageApplicationSupport {...singlePageSupport} />

        <IntelligentAssistantBlock {...saasIntelligantAssiatant} />

        <ContactsBlock callToActionBlockInfo={saasCallToActionBlock} />
      </main>
    </Layout>
  )
}
export default IndexPage
