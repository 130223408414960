import React, { useRef } from "react"
import { TitleWithDescriptionBlock } from "../moleculs/title-with-description-block"
import { withStyles } from "@material-ui/core"
import useShouldAnimate from "../../hooks/useShouldAnimate"
import useScrollPosition from "../../hooks/useScrollPosition"
import { OurProjectItem } from "../moleculs/our-project-item"
import { ContentContainer } from "../moleculs/content-container"


function TextBlock(props) {
  const { classes, title } = props
  const scrollPosition = useScrollPosition()
  const sectionRef = useRef()
  const sectionPosition = sectionRef?.current?.offsetTop
  const shouldAnimate = useShouldAnimate(sectionPosition, scrollPosition)

  return (
    <ContentContainer ref={sectionRef} customClass={classes.bg}>
      <TitleWithDescriptionBlock
        title={title}
        titleClassName={classes.customTitle}
        shouldAnimate={shouldAnimate}
        left={true}
      />
    </ContentContainer>
  )
}

const styles = theme => ({
  bg: {

  },
  customTitle: {
    color: "#4D4D4D",
    fontSize: 24,
    lineHeight: "32px",
    letterSpacing: "-0.5px"
  }
})

// eslint-disable-next-line
TextBlock = withStyles(styles)(TextBlock)

export { TextBlock }
