import React from "react"
import { TitleWithDescriptionBlock } from "./title-with-description-block"
import { withStyles, withWidth } from "@material-ui/core"
import { GatsbyImage, useFluidUrl } from "../images"
import useScrollPosition from "../../hooks/useScrollPosition"
import useShouldAnimate from "../../hooks/useShouldAnimate"
import { ContentContainer } from "./content-container"
import Grid from "@material-ui/core/Grid"
import { useInView } from "react-intersection-observer"

function IntelligentAssistantBlock({ classes, title, description, width }) {
  const img = useFluidUrl(  'intelligentAssistantImg')

  const scrollPosition = useScrollPosition()
  const sectionRef = React.useRef()
  const sectionPosition = sectionRef?.current?.offsetTop
  const shouldAnimate = useShouldAnimate(sectionPosition, scrollPosition)

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    delay: 500,
  })

  function prepareAnimationClasses(className) {
    if (!shouldAnimate) return `${className} ${classes.visible}`
    if (inView) return `${className} ${classes.itemAnimation}`
    return className
  }

  return (
    <ContentContainer ref={sectionRef}>
      <div className={classes.container}>
        <Grid
          container
          spacing={6}
          justifyContent={"space-between"}
        >
          <Grid
            item
            lg={6}
            md={12}
          >
            <div className={classes.textContainer}>
              <TitleWithDescriptionBlock
                title={title}
                description={description}
                titleClassName={classes.title}
                descriptionClassName={classes.description}
                shouldAnimate={shouldAnimate}
                animationDelay=".35s"
                left
              />
            </div>
          </Grid>
          <Grid
            item
            lg={6}
            md={12}
          >
            <div ref={ref} >
              <div className={prepareAnimationClasses(classes.dashboardImage)}>
                <img src={img.src} alt="" />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </ContentContainer>
  )
}

const styles = theme => ({
  ...theme.animations.fadeInUp,
  textContainer: {
    maxWidth: 520,

    [theme.breakpoints.down("md")]: {
      maxWidth: "auto"
    },
  },
  title: {
    color: theme.palette.text.secondary,
    fontSize: 40,
    lineHeight: "48px",
    fontWeight: 700,
    marginBottom: 30,
    letterSpacing: "-1px",

    [theme.breakpoints.down("xs")]: {
      fontSize: 30,
      lineHeight: "40px",
    },
  },
  description: {
    color: theme.palette.text.primary,
    fontSize: 24,
    lineHeight: "38px",
    fontWeight: 300,
    letterSpacing: "-0.5px",
    margin: 0,

    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
      lineHeight: "30px",
    },
  },
  dashboardImage: {
    maxWidth: 570,
    width: "100%",
    animationDelay: "0.6s !important",
    opacity: 0,

    "& img": {
      width: "100%",
      display: "block"
    },
  },
  itemAnimation: {
    ...theme.animations.itemFadeInUp,
  },
  visible: {
    opacity: 1,
  },
})

// eslint-disable-next-line
IntelligentAssistantBlock = withWidth()(
  withStyles(styles)(IntelligentAssistantBlock)
)

export { IntelligentAssistantBlock }
