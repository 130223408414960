import React, { useEffect, useState } from "react"
import { withStyles } from "@material-ui/core"
import { GatsbyImage, useFluidUrl } from "../images"
import withWidth from "@material-ui/core/withWidth"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { useInView } from "react-intersection-observer"
import useScrollPosition from "../../hooks/useScrollPosition"
import useShouldAnimate from "../../hooks/useShouldAnimate"
import { ContentContainer } from "./content-container"

function DifferentFromCompetitorsSaas(props) {
  const { classes, width } = props
  const img = useFluidUrl(  'differentsImg')

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    delay: 500,
  })

  const scrollPosition = useScrollPosition()
  const sectionRef = React.useRef()
  const sectionPosition = sectionRef?.current?.offsetTop
  const shouldAnimate = useShouldAnimate(sectionPosition, scrollPosition)

  function prepareAnimationClasses(className) {
    if (!shouldAnimate) return `${className} ${classes.visible}`
    if (inView) return `${className} ${classes.itemAnimation}`
    return className
  }

  function renderTitle() {
    const animationClasses = prepareAnimationClasses(classes.title)

    return (
      <Typography variant="h2" className={animationClasses}>
        How it’s different from competitors
      </Typography>
    )
  }

  function renderInfoBlocks() {
    const animationClasses = prepareAnimationClasses(classes.infoWrapper)

    return (
      <div className={animationClasses}>
        <Typography variant="body1" className={`${classes.infoBlock}`}>
          Astraload’s goal is to squash every single bit of info on every emulated user so that we can better understand how real users would perceive the UX when the website is under load
        </Typography>
      </div>
    )
  }

  return (
    <ContentContainer ref={sectionRef}>
      <Typography variant="h4" className={classes.topText}>
        Traditional load testing tools just generate API load and present very general data like API response time or page load time. And they miss tremendous amount of information about user experience that can be extracted and analyzed
      </Typography>
      <div className={classes.container}>
        <Grid
          container
          spacing={6}
          justifyContent={"space-between"}
        >
          <Grid
            item
            lg={6}
            md={12}
          >
            <div className={classes.textContainer}>
              {renderTitle()}
              {renderInfoBlocks()}
            </div>
          </Grid>
          <Grid
            item
            lg={6}
            md={12}
          >
            <div ref={ref} >
              <div className={prepareAnimationClasses(classes.dashboardImage)}>
                <img src={img.src} alt="" />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

    </ContentContainer>
  )


}

const styles = theme => {
  return {
    ...theme.animations.fadeInUp,
    container: {
      position: "relative"
    },
    topText: {
      marginBottom: 100,
      color: "#4D4D4D",

      [theme.breakpoints.down("sm")]: {
        marginBottom: 80,
      },

      [theme.breakpoints.down("xs")]: {
        marginBottom: 50,
      },
    },
    textContainer: {
      maxWidth: 520,

      [theme.breakpoints.down("md")]: {
        maxWidth: "auto"
      },
    },
    title: {
      color: theme.palette.text.link,
      fontSize: 54,
      lineHeight: "55px",
      fontWeight: 700,
      marginBottom: 30,
      maxWidth: 520,
      background: "linear-gradient(180deg, #BF31D7 0%, #4D18DD 100%)",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
      "background-clip": "text",
      "text-fill-color": "transparent",
      opacity: 0,

      [theme.breakpoints.down("xs")]: {
        fontSize: 30,
        lineHeight: "40px",
      },
    },
    infoWrapper: {
      opacity: 0
    },
    infoBlock: {
      color: theme.palette.text.primary,
      fontSize: 24,
      lineHeight: "38px",
      fontWeight: 300,

      [theme.breakpoints.down("xs")]: {
        fontSize: 20,
        lineHeight: "30px",
      },

      "&:not(:last-child)": {
        marginBottom: 40
      }
    },
    dashboardImage: {
      maxWidth: 570,
      width: "100%",
      opacity: 0,
      animationDelay: "0.3s !important",

      "& img": {
        width: "100%",
        display: "block"
      },
    },
    itemAnimation: {
      ...theme.animations.itemFadeInUp,
    },
    visible: {
      opacity: 1,
    },
  }
}

// eslint-disable-next-line
DifferentFromCompetitorsSaas = withWidth()(
  withStyles(styles)(DifferentFromCompetitorsSaas)
)

export { DifferentFromCompetitorsSaas }
